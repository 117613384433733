import { IAddFavorite, IBuyTicket } from '@/types/types';
import { Api } from './api';

export const listFavorites = <T>(): Promise<T> => {
  return Api().get(`/users/favorites`);
};

export const addFavorite = <T>(body: IAddFavorite): Promise<T> => {
  return Api().post(`/users/favorites`, body);
};

export const purchasedTickets = <T>(query: string): Promise<T> => {
  return Api().get(`/users/tickets${query ? query : ''}`);
};

export const buyTicket = <T>(payload: IBuyTicket): Promise<T> => {
  return Api().post(`/users/tickets`, payload);
};
