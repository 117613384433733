import {
  BaseState,
  IPagination,
  IResponse,
  IUser,
  PayloadState,
  IAPIResponse,
  IPaginateObject,
  IBrands,
  IPeople,
  IListAllFavorites,
  IAddFavorite,
  IPurchasedTickets,
} from '@/types/types';
import { of } from 'rxjs';
import { MutationTree, ActionTree, GetterTree, Module } from 'vuex';
import { RootState, IPaginate } from '../../types/types';
import {
  brands,
  deleteEvent,
  details,
  event,
  people,
  scheduleEvent,
  updateEvent,
} from '../../services/events.service';
import { isNetworkError } from '@/utils/helpers';
import moment from 'moment';
import { map } from 'rxjs/operators';
import router from '../../router/index';
import {
  addFavorite,
  listFavorites,
  purchasedTickets,
} from '@/services/users.service';

const state: BaseState<IUser> = {
  list: [],
  details: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
  },
  favoriteIds: [],
  favorites: [],
  purchasedTickets: [],
};

const mutations: MutationTree<BaseState<IUser>> = {
  UPDATE_PAGINATED_LIST(state, payload: Array<IUser>) {
    state.paginatedList = payload;
  },
  UPDATE_LIST(state, payload: Array<IUser>) {
    state.list = payload;
  },
  UPDATE_DETAILS(state, payload: IUser) {
    state.details = payload;
  },
  UPDATE_FAVORITE(state, payload: IListAllFavorites[]) {
    state.favorites = payload;
  },
  UPDATE_FAVORITE_IDs(state, payload: string[]) {
    state.favoriteIds = payload;
  },
  UPDATE_PURCHASED_TICKETS(state, payload: IPurchasedTickets[]) {
    state.purchasedTickets = payload;
  },
};

const actions: ActionTree<BaseState<IUser>, RootState> = {
  async addFavorite({ commit, dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IAPIResponse<IAddFavorite>>>(
        await addFavorite(payload)
      );
      response$
        .pipe(map((response) => response.data))
        .subscribe((data: IAPIResponse<IAddFavorite>) => {
          dispatch('listFavorites');
          dispatch('isLoading', false, { root: true });
          dispatch('snackBarMessage', data.statusMessage, {
            root: true,
          });
          dispatch('snackBarVisibility', true, { root: true });
        });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async listFavorites({ commit, dispatch }, payload) {
    try {
      dispatch('inProgress', true, { root: true });
      const response$ = of<IResponse<IAPIResponse<Array<IListAllFavorites>>>>(
        await listFavorites()
      );
      response$
        .pipe(map((response) => response.data))
        .subscribe((data: IAPIResponse<Array<IListAllFavorites>>) => {
          const Ids = [
            ...data.data
              .filter(({ eventId }) => eventId)
              .map(({ eventId }) => eventId?._id),
            ...data.data
              .filter(({ productId }) => productId)
              .map(({ productId }) => productId?._id),
          ];
          commit('UPDATE_FAVORITE_IDs', Ids);
          commit('UPDATE_FAVORITE', data.data);
          dispatch('inProgress', false, { root: true });
        });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('inProgress', false, { root: true });
    }
  },
  async listAllPurchasedTickets({ commit, dispatch }, query) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of<IResponse<IAPIResponse<Array<IPurchasedTickets>>>>(
        await purchasedTickets(query)
      );
      response$
        .pipe(map((response) => response.data))
        .subscribe((data: IAPIResponse<Array<IPurchasedTickets>>) => {
          console.log(data);
          commit('UPDATE_PURCHASED_TICKETS', data.data);
          dispatch('isPageLoading', false, { root: true });
        });
    } catch (e: any) {
      console.log(e);
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async details({ commit, dispatch }, payload) {
    try {
      dispatch('isPageLoading', true, { root: true });
      dispatch('isDialogLoading', true, { root: true });
      const { id } = payload;
      const response$ = of<IResponse<IAPIResponse<IUser>>>(await details(id));
      response$
        .pipe(map((response) => response.data))
        .subscribe((docs: IAPIResponse<IUser>) => {
          commit('UPDATE_DETAILS', docs.data);
          dispatch('isPageLoading', false, { root: true });
          dispatch('isDialogLoading', false, { root: true });
        });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },

  async brands({ commit, dispatch }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      dispatch('isDialogLoading', true, { root: true });
      const response$ = of<IResponse<IAPIResponse<IBrands>>>(await brands());
      response$
        .pipe(map((response) => response.data))
        .subscribe((data: IAPIResponse<IBrands>) => {
          commit('UPDATE_BRANDS', data.data);
          dispatch('isPageLoading', false, { root: true });
        });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async people({ commit, dispatch }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      dispatch('isDialogLoading', true, { root: true });
      const response$ = of<IResponse<IAPIResponse<IPeople>>>(await people());
      response$
        .pipe(map((response) => response.data))
        .subscribe((data: IAPIResponse<IPeople>) => {
          commit('UPDATE_PEOPLE', data.data);
          dispatch('isPageLoading', false, { root: true });
        });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<BaseState<IUser>, RootState> = {
  getAllFavorites: (state) => state.favorites,
  getAllFavoriteIDs: (state) => state.favoriteIds,
  getAllPurchasedTickets: (state) => state.purchasedTickets,
};

export const users: Module<BaseState<IUser>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
