/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AuthState,
  IAPIResponse,
  IChangePassword,
  ILoginResponse,
  IRegisterAccount,
  IResetPassword,
  IResponse,
  ISetAccountPassword,
  LoginState,
  RootState,
} from '@/types/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { of } from 'rxjs';
import { AES, enc } from 'crypto-js';

import SecureLS from 'secure-ls';
import router from '@/router';
import { isNetworkError } from '@/utils/helpers';
import {
  login,
  verifyAccount,
  resendVerificationCode,
  updateProfile,
  changePassword,
  forgotPassword,
  resetPassword,
  registerAccount,
  setPassword,
} from '@/services/auth.service';
import { map } from 'rxjs/operators';
import { me } from '@/services/auth.service';
import { IUser, IRegisterState, IVerifyAccounts } from '../../types/types';
import { uploadPhoto } from '../../services/auth.service';

const ls = new SecureLS({
  isCompression: false,
});
const namespaced = true;

const state: AuthState = {
  user: {
    token: '',
    userType: '',
    refreshToken: '',
    details: {
      _id: '',
      firstName: '',
      lastName: '',
      isAccountActive: true,
      isAccountBlocked: false,
      slug: '',
      isActive: true,
      phoneNumber: '',
    },
  },
  setUpResponse: {
    isActive: false,
    isAccountActive: false,
    phoneNumber: '',
    verifyAccountToken: '',
    setPasswordToken: '',
    setUpStatus: '',
  },
};

const mutations: MutationTree<AuthState> = {
  UPDATE_TOKEN(state, payload: IAPIResponse<ILoginResponse>) {
    state.user.token = payload?.data?.accessToken ?? null;
    state.user.userType = 'users';
    state.user.refreshToken = payload?.data?.refreshToken ?? null;
  },
  UPDATE_USER_DETAILS(state, payload) {
    state.user.details = payload;
  },
  UPDATE_SETUP_STATUS(state, payload: IRegisterAccount) {
    state.setUpResponse = payload;
  },
};
const actions: ActionTree<AuthState, RootState> = {
  async login(
    { commit, dispatch, rootState },
    payload: LoginState & { redirect: string | null }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IAPIResponse<ILoginResponse>>>(
        await login(payload)
      );
      response$.pipe(map((response) => response.data)).subscribe((data) => {
        dispatch('isLoading', false, { root: true });
        commit('UPDATE_TOKEN', data);

        // fetch the current user information
        dispatch('me');
        router.replace({ name: `account.dashboard` }).catch();
      });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async registerAccount(
    { commit, dispatch },
    payload: Pick<IUser, 'firstName' | 'lastName' | 'phoneNumber'>
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IAPIResponse<IRegisterAccount>>>(
        await registerAccount(payload)
      );
      response$.pipe(map((response) => response.data)).subscribe((register) => {
        dispatch('isLoading', false, { root: true });
        console.log(register);
        router.push({
          query: {
            token: register.data.verifyAccountToken,
          },
        });
        commit('UPDATE_SETUP_STATUS', register.data);
        dispatch(
          'snackBarMessage',
          register.statusMessage ?? `Account set up in progress`,
          { root: true }
        );
        dispatch('snackBarVisibility', true, { root: true });
      });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async verifyAccount({ commit, dispatch }, payload: IVerifyAccounts) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IAPIResponse<IRegisterAccount>>>(
        await verifyAccount(payload)
      );
      response$.pipe(map((response) => response.data)).subscribe((account) => {
        dispatch('isLoading', false, { root: true });
        router.push({
          query: {
            token: account.data?.setPasswordToken,
          },
        });
        commit('UPDATE_SETUP_STATUS', account.data);
        dispatch(
          'snackBarMessage',
          account.statusMessage ?? `Account verification done successfully`,
          {
            root: true,
          }
        );
        dispatch('snackBarVisibility', true, { root: true });
      });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async setAccountPassword({ commit, dispatch }, payload: ISetAccountPassword) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IAPIResponse<IRegisterAccount>>>(
        await setPassword(payload)
      );
      response$.pipe(map((response) => response.data)).subscribe((account) => {
        dispatch('isLoading', false, { root: true });
        router.push({
          query: {
            token: account.data?.setPasswordToken,
          },
        });
        commit('UPDATE_SETUP_STATUS', account.data);
        dispatch(
          'snackBarMessage',
          account.statusMessage ?? `Account verification done successfully`,
          {
            root: true,
          }
        );
        dispatch('snackBarVisibility', true, { root: true });
      });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async resendVerificationCode({ commit, dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      if (!payload.id) {
        router.push('/signin');
      }
      const query = AES.decrypt(payload.id, `K4iR0s4Fr1Ka`).toString(enc.Utf8);
      const response$ = of<IResponse<IRegisterState & { id: string }>>(
        await resendVerificationCode({ email: query })
      );
      response$.subscribe((account) => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarMessage', `Verification code successfully sent`, {
          root: true,
        });
        dispatch('snackBarVisibility', true, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch(
          'snackBarMessage',
          Array.isArray(e.response.data?.message)
            ? e?.response?.data?.message?.join(',')
            : e?.response?.data?.message ?? e?.message,
          {
            root: true,
          }
        );
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async me({ commit, dispatch }) {
    try {
      const response$ = of<IResponse<IAPIResponse<IUser>>>(await me());
      response$.pipe(map((response) => response.data)).subscribe((profile) => {
        const { password, ...rest } = profile.data;
        commit('UPDATE_USER_DETAILS', {
          ...rest,
          photo: rest.photo
            ? `${process.env.VUE_APP_BASE_URL}/static/${rest.photo}`
            : null,
        });
      });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
    }
  },
  async updateProfile({ commit, dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IUser>>(await updateProfile(payload));
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch(
          'snackBarMessage',
          `Personal information successfully updated`,
          {
            root: true,
          }
        );
        dispatch('me');
        dispatch('snackBarVisibility', true, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async uploadPhoto({ commit, dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<unknown>>(await uploadPhoto(payload));
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('me');
      });
    } catch (e) {
      dispatch('isLoading', false, { root: true });
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
    }
  },
  async changePassword({ commit, dispatch }, payload: IChangePassword) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<unknown>>(await changePassword(payload));
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarMessage', `Password successfully changed`, {
          root: true,
        });
        dispatch('snackBarVisibility', true, { root: true });
      });
    } catch (e) {
      dispatch('isLoading', false, { root: true });
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
    }
  },
  async forgotPassword({ commit, dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IUser>>(
        await forgotPassword(payload)
      ).pipe(map((account) => account.data));
      response$.subscribe((account) => {
        dispatch('isLoading', false, { root: true });
        dispatch(
          'snackBarMessage',
          `Check your email address <${account.email}> to follow the instructions in resetting your password.`,
          {
            root: true,
          }
        );
        dispatch('snackBarVisibility', true, { root: true });
        setTimeout(() => {
          router.replace({
            name: 'accounts.signin',
          });
        }, 2000);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async resetPassword({ commit, dispatch }, payload: IResetPassword) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IUser>>(await resetPassword(payload)).pipe(
        map((account) => account.data)
      );
      response$.subscribe((account) => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarMessage', `Password successfully reset`, {
          root: true,
        });
        dispatch('snackBarVisibility', true, { root: true });
        setTimeout(() => {
          router.replace({
            name: 'accounts.signin',
          });
        }, 2000);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async logOut({ commit, dispatch }, payload) {
    ls.removeAll();
    commit('UPDATE_TOKEN', payload);
    commit('UPDATE_REDIRECT_URL', '', {
      root: true,
    });
    await router.replace({
      name: 'accounts.signin',
    });
  },
};

const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated: (state) => {
    return !!state.user.token;
  },
  getToken: (state) => state.user.token,
  getUserDetails: (state) => state.user.details,
  getUserType: (state) => state.user.userType,
  getSetUpResponse: (state) => state.setUpResponse,
};

export const auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
