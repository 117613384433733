import { loadView, loadWidget } from '@/utils/helpers';

export const paths = [
  {
    path: '/',
    redirect: '/signin',
  },
  {
    path: '/signin',
    name: 'accounts.signin',
    component: loadView('greeters/Login'),
    meta: {
      pageTitle: 'Sign In',
      requiresAuth: false,
    },
  },
  {
    path: '/terms',
    name: 'accounts.terms',
    component: loadView('greeters/TermsAndConditions'),
    meta: {
      pageTitle: 'Terms Of Service',
      requiresAuth: false,
    },
  },
  {
    path: '/privacy-policy',
    name: 'accounts.privacy-policy',
    component: loadView('greeters/PrivacyPolicy'),
    meta: {
      pageTitle: 'Privacy Policy',
      requiresAuth: false,
    },
  },
  {
    path: '/signup/options',
    name: 'accounts.signup.options',
    component: loadView('greeters/SignUpOptions'),
    meta: {
      pageTitle: 'Sign Up Options',
      requiresAuth: false,
    },
  },
  {
    path: '/register/individual',
    name: 'accounts.signup.developer',
    component: loadView('greeters/RegisterAsDeveloper'),
    meta: {
      pageTitle: 'Sign Up',
      requiresAuth: false,
    },
  },
  {
    path: '/register/personal',
    name: 'accounts.signup.business',
    component: loadView('greeters/RegisterAsBusiness'),
    meta: {
      pageTitle: 'Sign Up',
      requiresAuth: false,
    },
  },
  {
    path: '/verify/account',
    name: 'accounts.verification',
    component: loadView('greeters/VerifyAccount'),
    meta: {
      pageTitle: 'Account Verification',
      requiresAuth: false,
    },
  },
  {
    path: '/migrate/account',
    name: 'accounts.migration',
    component: loadView('greeters/MigrateAccount'),
    meta: {
      pageTitle: 'Account Migration',
      requiresAuth: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'accounts.forgot-password',
    component: loadView('greeters/ForgotPassword'),
    meta: {
      pageTitle: 'Forgot Password',
      requiresAuth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'accounts.reset-password',
    component: loadView('greeters/ResetPassword'),
    meta: {
      pageTitle: 'Forgot Password',
      requiresAuth: false,
    },
  },
  {
    path: '/account-setup/completion',
    name: 'accounts.setup.completion',
    component: loadView('greeters/SetUpAccounts'),
    meta: {
      pageTitle: 'Account Setup Completion',
      requiresAuth: false,
    },
  },
  {
    path: `/verify/payment`,
    name: `account.verify.payment`,
    component: loadView(`pages/payments/VerifyPayment`),
    meta: {
      pageTitle: `Payment Verification`,
      requiresAuth: true,
    },
  },
  {
    path: '/',
    name: 'account',
    component: loadView('Master'),
    children: [
      {
        path: `/dashboard`,
        name: `account.dashboard`,
        component: loadView('pages/Dashboard'),
        meta: {
          pageTitle: `Dashboard`,
          requiresAuth: true,
        },
      },
      {
        path: `/events`,
        name: `account.events.list`,
        component: loadView(`pages/events/Index`),
        meta: {
          pageTitle: `Events`,
          requiresAuth: true,
        },
      },
      {
        path: `/events/:id`,
        name: `account.events.details`,
        component: loadView(`pages/events/Details`),
        meta: {
          pageTitle: `Event Details`,
          requiresAuth: true,
        },
      },
      {
        path: `/tickets`,
        name: `account.events.tickets`,
        component: loadView(`pages/events/Tickets`),
        meta: {
          pageTitle: `Tickets`,
          requiresAuth: true,
        },
      },
      {
        path: `/favorites`,
        name: `account.events.favorites`,
        component: loadView(`pages/events/Favorites`),
        meta: {
          pageTitle: `Favorites`,
          requiresAuth: true,
        },
      },
      {
        path: `/profile`,
        name: `account.profile`,
        component: loadView(`pages/profile/Index`),
        meta: {
          pageTitle: `Personal Information`,
          requiresAuth: true,
        },
      },
      {
        path: `/change/password`,
        name: `account.change.password`,
        component: loadView(`pages/profile/ChangePassword`),
        meta: {
          pageTitle: `Change Password`,
          requiresAuth: true,
        },
      },
    ],
  },

  /**
   * 404 redirect
   */
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/404',
    name: '404',
    component: loadWidget('pages/Error404'),
    meta: {
      pageTitle: `Not Found | National Commission On Culture`,
      requiresAuth: false,
    },
  },
];
