import {
  BaseState,
  IResponse,
  IEventActivity,
  IAPIResponse,
  IPaginateObject,
  IBrands,
  IPeople,
  ITickets,
  IBuyTicket,
  IDashboardCount,
} from '@/types/types';
import { of } from 'rxjs';
import { MutationTree, ActionTree, GetterTree, Module } from 'vuex';
import { RootState, IPaginate } from '../../types/types';
import {
  analytics,
  brands,
  details,
  event,
  people,
} from '../../services/events.service';
import { isNetworkError } from '@/utils/helpers';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { buyTicket } from '@/services/users.service';
import router from '@/router';

const state: BaseState<IEventActivity> = {
  list: [],
  brands: [],
  paginatedList: [],
  details: {
    name: '',
    activity: '',
    address1: '',
    noticesValidFor: {
      startDateTime: '',
      endDateTime: '',
    },
    description: '',
    isPublished: false,
    occurrenceType: '',
    status: '',
    type: '',
    slug: '',
    gallery: [],
    actionType: '',
    tags: [],
    tickets: [],
  },
  paginate: {
    page: 0,
    totalPages: 1,
    limit: 30,
  },
  people: [],
  ticketDetails: null,
  analytics: {
    totalEvents: 0,
    totalPeople: 0,
    totalBrands: 0,
  },
};

const mutations: MutationTree<BaseState<IEventActivity>> = {
  UPDATE_PAGINATED_LIST(state, payload: Array<IEventActivity>) {
    state.paginatedList = payload;
  },
  UPDATE_LIST(state, payload: Array<IEventActivity>) {
    state.list = payload;
  },
  UPDATE_DETAILS(state, payload: IEventActivity) {
    state.details = payload;
  },
  UPDATE_PAGINATION(state, payload: IPaginate) {
    state.paginate = {
      page: payload.page,
      totalPages: payload.totalPages,
      limit: payload.limit,
    };
  },
  UPDATE_BRANDS(state, payload: IBrands) {
    state.brands = payload;
  },
  UPDATE_PEOPLE(state, payload: IPeople) {
    state.people = payload;
  },
  UPDATE_TICKET_DETAILS(state, payload: ITickets) {
    state.ticketDetails = payload;
  },
  UPDATE_ANALYTICS(state, payload) {
    state.analytics = payload;
  },
};

const actions: ActionTree<BaseState<IEventActivity>, RootState> = {
  async analytics({ commit, dispatch }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of<IResponse<IAPIResponse<IDashboardCount>>>(
        await analytics()
      );
      response$
        .pipe(map((response) => response.data))
        .subscribe((data: IAPIResponse<IDashboardCount>) => {
          commit('UPDATE_ANALYTICS', data.data);
          dispatch('isPageLoading', false, { root: true });
        });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },

  async list({ commit, dispatch }, payload) {
    try {
      dispatch('isPageLoading', true, { root: true });
      dispatch('isDialogLoading', true, { root: true });
      const { query } = payload;
      const response$ = of<
        IResponse<IAPIResponse<IPaginateObject<IEventActivity>>>
      >(await event(query));

      response$
        .pipe(map((response) => response.data))
        .subscribe((data: IAPIResponse<IPaginateObject<IEventActivity>>) => {
          const { page, totalPages, limit } = data.data;
          commit('UPDATE_LIST', data.data.docs);
          dispatch('isPageLoading', false, { root: true });
          dispatch('isDialogLoading', false, { root: true });
          commit('UPDATE_PAGINATION', {
            page,
            totalPages,
            limit,
          });
          dispatch('isPageLoading', false, { root: true });
        });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },

  async details({ commit, dispatch }, payload) {
    try {
      dispatch('isPageLoading', true, { root: true });
      dispatch('isDialogLoading', true, { root: true });
      const { id } = payload;
      const response$ = of<IResponse<IAPIResponse<IEventActivity>>>(
        await details(id)
      );
      response$
        .pipe(map((response) => response.data))
        .subscribe((docs: IAPIResponse<IEventActivity>) => {
          commit('UPDATE_DETAILS', docs.data);
          dispatch('isPageLoading', false, { root: true });
          dispatch('isDialogLoading', false, { root: true });
        });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },

  async brands({ commit, dispatch }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      dispatch('isDialogLoading', true, { root: true });
      const response$ = of<IResponse<IAPIResponse<IBrands>>>(await brands());
      response$
        .pipe(map((response) => response.data))
        .subscribe((data: IAPIResponse<IBrands>) => {
          commit('UPDATE_BRANDS', data.data);
          dispatch('isPageLoading', false, { root: true });
        });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async people({ commit, dispatch }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      dispatch('isDialogLoading', true, { root: true });
      const response$ = of<IResponse<IAPIResponse<IPeople>>>(await people());
      response$
        .pipe(map((response) => response.data))
        .subscribe((data: IAPIResponse<IPeople>) => {
          commit('UPDATE_PEOPLE', data.data);
          dispatch('isPageLoading', false, { root: true });
        });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async buyTicket({ commit, dispatch }, payload: IBuyTicket) {
    try {
      dispatch('isDialogLoading', true, { root: true });
      const response$ = of<IResponse<IAPIResponse<ITickets>>>(
        await buyTicket(payload)
      );
      response$
        .pipe(map((response) => response.data))
        .subscribe((data: IAPIResponse<ITickets>) => {
          dispatch('snackBarMessage', data.statusMessage, {
            root: true,
          });
          dispatch('snackBarVisibility', true, { root: true });
          dispatch(
            'updateDialog',
            { idx: 'add', state: false },
            { root: true }
          );
          dispatch('isDialogLoading', false, { root: true });
          router.push({ name: `account.events.tickets` }).catch();
        });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.supportMessage, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.supportMessage, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async loadEventList(
    { dispatch },
    payload: { executionTimestamp: string; isPaginated: boolean }
  ): Promise<void> {
    setTimeout(() => {
      dispatch('list', {
        query: `?activeDate=${moment(payload?.executionTimestamp).format(
          'YYYY-MM-DD'
        )}&isPaginated=${payload.isPaginated ?? false}`,
      });
    }, 1000);
  },
};

const getters: GetterTree<BaseState<IEventActivity>, RootState> = {
  getAllEvents: (state) => state.list,
  getEventDetails: (state) => state.details,
  getAllPaginatedEvents: (state) => state.paginatedList,
  getAllBrands: (state) => state.brands,
  getAllPeople: (state) => state.people,
  getTicketDetails: (state) => state.ticketDetails,
  getPagination: (state) => state.paginate,
  getAnalytics: (state) => state.analytics,
};

export const events: Module<BaseState<IEventActivity>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
